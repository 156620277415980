@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?xaxqvc');
  src:  url('fonts/icomoon.eot?xaxqvc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xaxqvc') format('truetype'),
    url('fonts/icomoon.woff?xaxqvc') format('woff'),
    url('fonts/icomoon.svg?xaxqvc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-briefcase:before {
  content: "\e900";
}
.icon-portfolio:before {
  content: "\e900";
}
.icon-suitcase:before {
  content: "\e900";
}
.icon-work:before {
  content: "\e900";
}
.icon-job:before {
  content: "\e900";
}
.icon-employee:before {
  content: "\e900";
}
.icon-book:before {
  content: "\e901";
}
.icon-read:before {
  content: "\e901";
}
.icon-reading:before {
  content: "\e901";
}
.icon-airplane:before {
  content: "\e902";
}
.icon-travel:before {
  content: "\e902";
}
.icon-flight:before {
  content: "\e902";
}
.icon-plane:before {
  content: "\e902";
}
.icon-transport:before {
  content: "\e902";
}
.icon-fly:before {
  content: "\e902";
}
.icon-vacation:before {
  content: "\e902";
}
.icon-cloud:before {
  content: "\e903";
}
.icon-weather:before {
  content: "\e903";
}
