$primary: #EDAE49;
$primary2: #F9EDCC;
$link: $primary;
$bg: #FFF6DD;

@import "~bulma";
@import "./icomoon/style.css";

.top-block {
  margin-bottom: 50px;
}

body {
  background-color: $bg;
  min-height: 100vh;
  background-image: url("../public/shape.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.card {
  background: #FFFFFF;
  box-shadow: 0 0 40px 11px rgba(0,0,0,0.11);
  border-radius: 15px;
  position: relative;

  .card-footer-item {
    background: $primary2;
    font-weight: bold;

    &:hover {
      color: $primary;
    }
  }

  .card-footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
  }

  &--with-icon {
    padding-top: 60px;
    margin-top: 60px;
  }

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    box-shadow: 0 0 40px 11px rgba(0,0,0,0.11);
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    background: $white;
    margin-left: auto;
    margin-right: auto;
    color: $primary;
    background: $primary2;
  }
}

.hero {
  padding-top: 60px;
  padding-bottom: 60px;

  .container {
    width: 100%;
  }
}

.content-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.blob.green {
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.subtitle-blob {
  display: flex;
  height: 27px;
  align-items: center;
  margin-left: -10px;
}

.table {
  tr:last-child {
    td,
    th {
      border-bottom: 0 !important;
    }
  }
}